import { defineStore } from 'pinia';
import API_USER from '~/api/user-enter';
import { AuthClass } from '~/constant/auth-enum';
import { AuthenticationForm } from '~/models/user';
import { InferEnumValueKey } from '~/utils/enum-util';

const { NOT_CERTIFIED } = AuthClass;
export const authStore = defineStore('auth', {
  state: () => {
    // 认证信息
    const authenticationData = {} as AuthenticationForm;
    // 平台认证状态
    const sysUserStatus: InferEnumValueKey<typeof AuthClass> = NOT_CERTIFIED.value;
    // 移动云认证状态
    const mobileUserStatus: InferEnumValueKey<typeof AuthClass> = NOT_CERTIFIED.value;
    // 百度云认证状态
    const bdUserStatus: InferEnumValueKey<typeof AuthClass> = NOT_CERTIFIED.value;
    return {
      authenticationData,
      mobileUserStatus,
      sysUserStatus,
      bdUserStatus
    };
  },
  actions: {
    // 获取所有认证信息
    async fetchAuthenticationData () {
      // 获取认证信息
      const authForm = await API_USER.getUserRealName().catch(() => void 0);
      // 获取认证状态
      const authStatus = await API_USER.getUserRealNameStatus().catch(() => void 0);
      if (authForm) {
        this.authenticationData = authForm;
      }
      if (authStatus) {
        const { sysUserStatus, mobileUserStatus, bdUserStatus } = authStatus;
        this.sysUserStatus = sysUserStatus;
        this.mobileUserStatus = mobileUserStatus;
        this.bdUserStatus = bdUserStatus;
      }
    },
  },
  getters: {},
});